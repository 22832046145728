<template>
  <div
    v-if="issue != null"
    class="IssueChat"
  >
    <IssueChatMessages
      :messages="messages"
      :height="messagesHeight"
      class="IssueChat__messages"
      @click:message="newMessage.parentUuid = $event.messageId; $nextTick(focusChatPrompt)"
    />

    <div
      v-if="pollError"
      class="IssueChat__error"
    >
      <b v-text="$t('issue.ConnectionError')" />
      <v-btn
        text
        tile
        color="white"
        :loading="pollTimeoutId != null"
        :disabled="pollTimeoutId != null"
        @click="startPolling()"
      >
        {{ $t('issue.Retry') }}
      </v-btn>
    </div>

    <ChatPrompt
      ref="chatPrompt"
      v-model="newMessage.text"
      class="IssueChat__prompt"
      :reply-to-username="parentMessageAuthorName"
      @submit="submitNewMessage($event.value, $event.files)"
      @clear-reply="newMessage.parentUuid = null"
    />
  </div>
</template>

<script>
import { CHAT_POLL_INTERVAL } from '../constants'

import Issue from '../store/orm/issue'

import ChatPrompt from './ChatPrompt'
import IssueChatMessages from './IssueChatMessages'

export default {
  name: 'IssueChat',

  components: {
    ChatPrompt,
    IssueChatMessages,
  },

  props: {
    issueUuid: { type: String, required: true },
    messagesHeight: { type: String, default: 'calc(100vh - 122px - 80px - 48px)' },
  },

  data() {
    return {
      newMessage: { text: '', parentUuid: null },
      pollTimeoutId: null,
      pollError: false,
    }
  },

  computed: {
    issue() {
      const { issueUuid } = this
      return Issue.find(issueUuid)
    },

    messages() {
      const { $store, issueUuid } = this
      return $store.getters['issueChat/list'](issueUuid)
    },

    parentMessage() {
      const { messages, newMessage: { parentUuid } } = this
      return parentUuid && messages &&
        (messages.find(m => m.id === parentUuid) ?? null)
    },

    parentMessageAuthorName() {
      const { parentMessage } = this
      if (!parentMessage) return null
      return parentMessage.author?.trim?.() || this.$t('issue.Noname')
    },
  },

  watch: {
    issue: {
      handler(issue) {
        if (issue) this.startPolling()
        else this.stopPolling()
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.stopPolling()
  },

  methods: {
    fetchMessages() {
      const { $store, issue, issueUuid } = this
      if (!issue) throw new Error("Issue isn't loaded")
      return $store.dispatch('issueChat/getForIssue', { projectUuid: issue.projectID, issueUuid })
    },

    async fetchMessagesTick() {
      try {
        await this.fetchMessages()
        this.pollError = false
        if (this.pollTimeoutId) {
          this.pollTimeoutId = setTimeout(this.fetchMessagesTick, CHAT_POLL_INTERVAL)
        }
      } catch (e) {
        this.pollError = true
      } finally {
        this.pollTimeoutId = null
      }
    },

    stopPolling() {
      clearTimeout(this.pollTimeoutId)
      this.pollTimeoutId = null
    },

    startPolling() {
      this.stopPolling()
      this.pollTimeoutId = setTimeout(this.fetchMessagesTick, 0)
    },

    submitNewMessage(text, files) {
      const { $store, issue, issueUuid } = this

      if (!issue) throw new Error("Issue isn't loaded")
      if (
        files.length &&
        !confirm(this.$t('issue.FileUploadNotImplementedSendQ'))
      ) return Promise.resolve(undefined)

      const { parentUuid } = this.newMessage

      // reset chat form
      this.newMessage.text = ''
      this.newMessage.parentUuid = null
      this.$refs.chatPrompt?.resetFiles?.() // eslint-disable-line
      this.$nextTick(this.focusChatPrompt)

      return $store.dispatch('issueChat/create', {
        projectUuid: issue.projectID,
        issueUuid,
        text,
        parentUuid,
      })
        .catch(() => {
          // this.newMessage = { text, parentUuid, files }
          this.newMessage = { text, parentUuid }
        })
    },

    focusChatPrompt() {
      // eslint-disable-next-line
      this.$refs.chatPrompt?.focus?.()
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueChat
  display: flex
  flex-direction: column
  position: relative

  &__messages
    flex: 1

  &__error
    position: absolute
    bottom: 76px
    left: 0
    right: 0
    background: rgba(255, 80, 101, .8)
    color: white
    padding: 0 16px
    display: flex
    align-items: center
    justify-content: space-between
    z-index: 2

  &__prompt
    flex: 0 0 76px
</style>
