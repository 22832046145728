<template>
  <ChatSimpleMessage
    class="IssueChatMessage"
    :user="messageAuthor"
    :avatar-props="{ icon: isBrig ? '$logo-color-hive' : '$logo-color-apiary' }"
    :text="message.text"
    :datetime="message.timestamp"
    :quote-user="parentMessageAuthor"
    :quote-text="parentMessage && parentMessage.text"
    :align-right="false"
    :dark="!isBrig"
    :state="message.status"
    :ripple="ripple"
    format="MARKDOWN"
    @go-to-quote="$emit('go-to-quote', { messageId: message.parentUuid })"
  >
    <template #actions="{ color }">
      <v-spacer />
      <CommonIconButton
        icon="mdi-reply-outline"
        :color="color"
        @click="$emit('click', $event)"
      />
    </template>
  </ChatSimpleMessage>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import md5 from 'md5'

import { SERVER_TYPE } from '../constants'

import ChatSimpleMessage from './ChatSimpleMessage'

const getUuid = (msg) => uuidv4({
  random: md5(msg).slice(0, 16).split('').map(s => s.charCodeAt(0)),
})

export default {
  name: 'IssueChatMessage',

  components: {
    ChatSimpleMessage,
  },

  props: {
    // see type `MessageToGet` and store module `issueChat`
    message: { type: Object, required: true },
  },

  computed: {
    isBrig() {
      return this.message.sourceType === SERVER_TYPE.brig
    },

    ripple() {
      const { $listeners, isBrig } = this
      if (!$listeners) return null
      if (!isBrig) return { class: 'white--text' }
      return { class: 'blue--text text--lighten-2' }
    },

    messageAuthor() {
      const { message: { author } } = this
      const [firstName, ...restOfName] = (author || '')
        .split(' ')
        .filter(Boolean)
      return {
        id: getUuid(author),
        firstName: firstName || '',
        lastName: restOfName.join(' '),
        userLogin: this.$t('issue.Noname'),
      }
    },

    parentMessage() {
      const { $store, message: { parentUuid } } = this
      return parentUuid
        ? $store.getters['issueChat/get'](parentUuid)
        : null
    },

    parentMessageAuthor() {
      const { parentMessage } = this
      if (!parentMessage) return null
      const [firstName, ...restOfName] = (parentMessage.author || '')
        .split(' ')
        .filter(Boolean)
      return {
        id: getUuid(parentMessage.author),
        firstName: firstName || '',
        lastName: restOfName.join(' '),
        userLogin: this.$t('issue.Noname'),
      }
    },
  },
}
</script>
