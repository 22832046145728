<!-- Presentational component to render a chat message.
Doesn't know anything about BL/store.
-->

<template>
  <div
    class="ChatSimpleMessage"
    :class="{
      'ChatSimpleMessage--dark': dark,
      'ChatSimpleMessage--align-right': alignRight,
      'ChatSimpleMessage--semi-transparent': semiTransparent,
    }"
  >
    <UserAvatar
      :ripple="ripple"
      class="ChatSimpleMessage__avatar"
      :user="user"
      v-bind="{ size: 'lg', color: dark ? '#666699' : 'white', ...avatarProps }"
      @click="$emit('click', $event)"
    />

    <div
      v-ripple="$listeners.click ? ripple : false"
      class="ChatSimpleMessage__card"
      :style="$listeners.click ? { cursor: 'pointer' } : null"
      :tabindex="$listeners.click ? '0' : null"
      @click="$emit('click', $event)"
      @keyup.enter="$emit('click', $event)"
      @keyup.space="$emit('click', $event)"
    >
      <div class="ChatSimpleMessage__card-content">
        <div class="ChatSimpleMessage__heading">
          <span
            class="ChatSimpleMessage__username"
            v-text="userDisplayName"
          />
          <time
            class="ChatSimpleMessage__time"
            v-text="renderedTime"
          />

          <v-icon
            v-if="stateIcon"
            :size="14"
            class="ml-1"
            :color="stateIconColor"
            v-text="stateIcon"
          />
        </div>

        <div
          v-if="quoteUser"
          v-ripple="{ class: 'app-ripple' }"
          class="ChatSimpleMessage__quote"
          tabindex="0"
          role="button"
          @click.stop="$emit('go-to-quote')"
          @keyup.enter.stop="$emit('go-to-quote')"
          @keyup.space.stop="$emit('go-to-quote')"
          @mousedown.stop
          @touchstart.stop
        >
          <span v-text="quoteUserDisplayName" />

          <span
            class="ml-2 text-truncate"
            v-text="quoteText"
          />
        </div>

        <AppMarkdownViewer
          v-if="markdownToRender != null"
          class="ChatSimpleMessage__text"
          :source="markdownToRender"
        />
        <div
          v-else
          class="ChatSimpleMessage__text"
          v-text="text"
        />

        <div
          v-if="attachments && attachments.length"
          class="ChatSimpleMessage__attachments"
          @click.stop
          @mousedown.stop
          @touchstart.stop
        >
          <pre
            v-for="file in attachments"
            :key="file.id"
            class="ChatSimpleMessage__attachment"
            @contextmenu.capture.stop="/**/"
            v-text="file"
          />
        </div>

        <span
          v-if="note"
          class="ChatSimpleMessage__note"
          :style="noteStyle"
          :title="noteTitle"
          v-text="note"
        />
      </div>

      <div
        v-if="$slots.actions || $scopedSlots.actions"
        class="ChatSimpleMessage__card-actions"
        @click.stop
        @mousedown.stop
        @touchstart.stop
        @keydown.stop
      >
        <slot
          name="actions"
          :color="dark ? '#666699' : '#A09EB9'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { helpers } from '@hexway/shared-front'
import j2m from 'jira2md'

import { CHAT_MESSAGE_STATUS as STATE } from '../constants'
import { fmtDt } from '../helpers'

import UserAvatar from './UserAvatar'

const FORMAT = Object.freeze({
  JIRA: 'JIRA',
  MARKDOWN: 'MARKDOWN',
  PLAIN: 'PLAIN',
})
const FORMATS = Object.freeze(Object.values(FORMAT))

export default {
  name: 'ChatSimpleMessage',

  components: {
    UserAvatar,
  },

  props: {
    // data to display
    user: { type: Object, required: true },
    avatarProps: { type: Object, default: () => ({}) },
    text: { type: String, required: true },
    datetime: { type: [String, Number], default: null },

    // quote (reply to message) preview
    quoteUser: { type: Object, default: null },
    quoteText: { type: String, default: '' },

    // Array of graph file nodes
    // Array<Object>
    attachments: { type: Array, default: () => [] },
    // Determines message icon
    state: {
      type: String,
      default: STATE.delivered,
      validator: v => Object.values(STATE).includes(v),
    },

    format: {
      type: String,
      default: 'PLAIN',
      validator: v => FORMATS.includes(v),
    },

    // additional styling, used for messages from brig and for ripples
    dark: { type: Boolean, default: false },
    alignRight: { type: Boolean, default: false },
    ripple: { type: [Boolean, Object], default: null },

    note: { type: String, default: null },
    noteColor: { type: String, default: null },
    noteTitle: { type: String, default: null },

    semiTransparent: { type: Boolean, default: false },
  },

  data() {
    return {
      FORMAT,
    }
  },

  computed: {
    // converts `datetime` to user-friendly string
    renderedTime() {
      const { datetime } = this
      if (datetime == null) return null
      return fmtDt(datetime)
    },

    stateIcon() {
      const { state } = this
      return {
        [STATE.clientNew]: 'mdi-clock-time-four-outline',
        [STATE.clientPurgatory]: 'mdi-trash-can-outline',
        [STATE.new]: 'mdi-check',
        [STATE.sent]: 'mdi-check',
        [STATE.delivered]: 'mdi-check-all',
        [STATE.failed]: 'mdi-alert-circle-outline',
      }[state] ?? null
    },

    stateIconColor() {
      const { state, dark } = this
      return {
        [STATE.failed]: '#FF5065',
      }[state] ?? (dark ? 'rgba(255, 255, 255, 0.54)' : '#757575')
    },

    userDisplayName() {
      const { user } = this
      return [user.firstName, user.lastName]
        .map(s => s?.trim?.())
        .filter(Boolean)
        .join(' ') || user.userLogin || user.userEmail
    },

    quoteUserDisplayName() {
      const { quoteUser: user } = this
      if (!user) return null
      return [user.firstName, user.lastName]
        .map(s => s?.trim?.())
        .filter(Boolean)
        .join(' ') || user.userLogin || user.userEmail
    },

    markdownToRender() {
      const { text, format } = this
      if (format === FORMAT.JIRA) {
        try {
          return j2m.to_markdown(text)
        } catch (e) {
          return null
        }
      }
      if (format === FORMAT.MARKDOWN) return text
      return null
    },

    noteStyle() {
      const { noteColor } = this
      return noteColor
        ? { color: helpers.vuetifyColorToHex(this.$vuetify, noteColor) }
        : {}
    },
  },
}
</script>

<style lang="sass" scoped>
.ChatSimpleMessage
  $self: &

  display: flex
  align-items: flex-start
  justify-content: flex-start

  & + &
    margin-top: 16px

  &--align-right
    flex-direction: row-reverse

  &--semi-transparent
    opacity: .5
    pointer-events: none
    user-select: none

  &__avatar
    margin-right: 16px
    flex: 0 0 40px
  &--align-right &__avatar
    margin-right: 0
    margin-left: 16px

  &__card
    min-height: 72px
    min-width: 240px
    flex: 1 1 auto
    padding: 8px 32px 8px 16px
    overflow: hidden
    text-overflow: ellipsis
    background: white
    border-radius: 4px
    display: flex
    align-items: center
    position: relative

    &:hover, &:active, &:focus, &:focus-within
      #{$self}__card-actions
        opacity: 1

  &--dark &__card
    background: #666699
    color: #fff

    &::v-deep .toastui-editor-contents
      p, h1, h2, h3, h4, h5, h6, li, a
        color: #fff

  &__card-content
    overflow: hidden
    text-overflow: ellipsis
    width: 100%

  &__card-actions
    position: absolute
    top: 0
    right: 0
    height: 100%
    width: 32px
    background: #F5F5F9
    display: flex
    flex-direction: column
    align-items: center
    padding: 8px 0
    border-radius: 4px
    opacity: 0
    transition: all 100ms ease
  &--dark &__card-actions
    background: #A09EB9

  &__heading
    font-size: 12px
    line-height: 16px
    margin-bottom: 4px
    display: inline-flex
    height: 16px
    align-items: center
    width: 100%

  &__username
    color: #41415A
    font-weight: 500
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.005em
    margin-right: auto
  &--dark &__username
    color: #C1C1D2

  &__time
    color: rgba(56, 54, 77, .6)
  &--dark &__time
    color: rgba(255, 255, 255, .7)

  &__quote
    border-radius: 4px
    border-left: 4px solid #2196F3
    background: #C1C1D2
    font-size: 12px
    line-height: 16px
    color: #38364D
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    margin: 4px 0
    padding: 8px 12px
    display: flex
    align-items: center
    cursor: pointer

  &__text
    margin-top: 4px
    // white-space: pre-wrap
    overflow: hidden
    text-overflow: ellipsis
    line-height: 20px
  &--dark &__text
    color: #fff

  &__attachments
    margin-top: 4px
    display: flex
    flex-direction: column

  &__attachment
    & + &
      margin-top: 4px

  &__note
    display: inline-block
    background: #F5F5F9
    border-radius: 2px
    padding: 0 4px
    font-size: 12px
    line-height: 18px
    letter-spacing: 0.005em
    margin-top: 4px
</style>
