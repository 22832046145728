<template>
  <div
    ref="scrollable"
    class="IssueChatMessages"
    :style="{ height }"
  >
    <div
      class="d-flex flex-column justify-end"
      style="min-height: 100%"
    >
      <IssueChatMessage
        v-for="message in messages"
        :key="message.id"
        :message="message"
        :data-message-id="message.id"
        @click="$emit('click:message', { mouseEvent: $event, messageId: message.id })"
        @go-to-quote="scrollToMessage($event.messageId)"
      />
    </div>
  </div>
</template>

<script>
import IssueChatMessage from './IssueChatMessage'

export default {
  name: 'IssueChatMessages',

  components: {
    IssueChatMessage,
  },

  props: {
    messages: { type: Array, default: null },
    height: { type: String, default: 'calc(100vh - 80px - 80px - 48px)' },
  },

  watch: {
    'messages.length': {
      handler(length) {
        if (length) this.$nextTick(this.scrollToBottom)
      },
      immediate: true,
    },
  },

  methods: {
    scrollToBottom() {
      // eslint-disable-next-line
      this.$refs.scrollable?.scroll?.(0, Number.MAX_SAFE_INTEGER)
    },

    scrollToMessage(messageId) {
      const el = this.$el.querySelector(`[data-message-id="${messageId}"]`)
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
        el.style.outline = '2px solid rgba(0, 0, 0, 0.2)'
        setTimeout(() => { el.style.outline = null }, 1000)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueChatMessages
  background: #EDEEF5
  padding: 30px 28px
  overflow: hidden auto
  scroll-behavior: smooth
</style>
