<template>
  <div class="ChatPrompt">
    <v-textarea
      ref="input"
      v-model="model"
      class="ChatPrompt__input"
      :placeholder="$t('issue.MessageHere')"
      hide-details
      outlined
      dense
      auto-grow
      rows="1"
      background-color="white"
      autocomplete="off"
      name="comment-text"
      @keydown.ctrl.enter.exact.stop="maybeSubmit"
      @keydown.meta.enter.exact.stop="maybeSubmit"
      @keydown.8.exact="!model && $emit('clear-reply')/* clear reply on backspace hit */"
      @keydown.ctrl.8.exact="!model && $emit('clear-reply')/* clear reply on backspace hit */"
      @keydown.shift.8.exact="!model && $emit('clear-reply')/* clear reply on backspace hit */"
      @keydown.esc.exact="$emit('clear-reply')/* clear reply on esc hit */"
      @keydown.stop="/* prevent propagation of keydown */"
      @keyup.stop="/* prevent propagation of keyup */"
      @keypress.stop="/* prevent propagation of keypress */"
      @drop.prevent="onFilesDrop($event)"
    >
      <template
        v-if="!!replyToUsername"
        #prepend-inner
      >
        <v-btn
          v-if="false"
          icon
          @click="$refs.fileInput.click()"
        >
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>

        <span
          v-if="replyToUsername != null"
          class="mr-2 mt-1"
          v-text="replyToUsername"
        />
      </template>

      <template #append>
        <input
          v-if="false"
          ref="fileInput"
          class="ChatPrompt__file-hidden-input"
          name="files"
          type="file"
          multiple
          @change="onFilesSelected($event)"
        >

        <span
          v-if="files.length"
          @click="$refs.fileInput.click()"
          v-text="$t('issue.filesNumber', { filesNumber: files.length })"
        />

        <CommonIconButton
          v-if="!loading && clearable"
          icon="mdi-close"
          class="mx-2"
          @click="$emit('input', ''); $emit('clear')"
        />
      </template>

      <template #append-outer>
        <v-tooltip
          left
          open-delay="400"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              depressed
              color="#2196F3"
              :loading="loading"
              width="40"
              min-width="40"
              class="mt-n1"
              style="margin-left: 11px"
              v-bind="attrs"
              @click="$emit('submit', { value: model, files })"
              v-on="on"
            >
              <v-icon color="white">
                mdi-send
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t('issue.EnterToSend', { modifierSymbol }) }}
          </span>
        </v-tooltip>
      </template>
    </v-textarea>
  </div>
</template>

<script>
export default {
  name: 'ChatPrompt',

  props: {
    value: { type: String, default: '' }, // v-model
    replyToUsername: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },

  data() {
    return {
      model: this.value,
      files: [],
    }
  },

  computed: {
    modifierSymbol() {
      return (navigator?.platform || '').toLowerCase().startsWith('mac')
        ? '⌘'
        : 'Ctrl'
    },
  },

  watch: {
    // v-model
    value(value) {
      if (this.model !== value) {
        this.model = value
        this.resetFiles()
      }
    },
    model(model) { this.$emit('input', model) },
  },

  methods: {
    // NB! called by the parent component through $refs
    resetFiles() {
      this.files = []
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null
      }
    },

    onFilesSelected(event) {
      this.files = Array.from(event.target.files || [])
    },

    onFilesDrop(event) {
      const files = Array.from(event.dataTransfer.files || [])
      this.files.push(...files)
    },

    // NB! called by the parent component through $refs
    focus() {
      this.$refs.input.focus()
      const scrollToBottom = () =>
        this.$refs.input.$el
          .querySelector('.v-text-field__slot')
          .scroll(0, Number.MAX_SAFE_INTEGER)
      scrollToBottom();
      [100, 200, 300, 400].forEach(ms =>
        setTimeout(scrollToBottom, ms))
    },

    // submits form (but only if there is some text or files)
    maybeSubmit() {
      const { model, files } = this
      if (!model.trim() && !files.length) return
      this.$emit('submit', { value: model, files })
    },
  },
}
</script>

<style lang="sass" scoped>
.ChatPrompt
  padding: 18px 32px 0
  background: #F5F5F9
  border-top: 2px solid #E6E6F2

  &__input
    display: flex
    align-items: center
    font-size: 13px
    padding: 0
    margin: 0
    overflow: hidden

    //::v-deep
    //  textarea
    //    margin-top: 0 !important
    //    padding-top: 8px
    //    padding-bottom: 8px
    //    line-height: 150%
    //    letter-spacing: .00001em
    //
    //  .v-text-field__slot
    //    max-height: 80px
    //    overflow-y: auto
    //  .v-input__prepend-outer
    //    margin: auto 0 !important
    //    height: 40px
    //    display: flex
    //    align-items: center
    //    padding-right: 12px
    //  .v-input__prepend-inner
    //    margin: auto -3px auto -1px !important
    //    height: 40px
    //    display: flex
    //    align-items: center
    //  .v-input__append-inner
    //    margin: auto -4px auto 6px !important
    //    height: 40px
    //    display: flex
    //    align-items: center

  &__file-hidden-input
    position: absolute
    opacity: 0
    pointer-events: none

  &__input-lodaing
    position: absolute
    right: 140px
    align-self: center
</style>
